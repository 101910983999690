$(function() {
  $('#application-modal').on('show.bs.modal', function (event) {
    let url = $(event.relatedTarget).attr("href")
    $.ajax({
      url: url,
      success: function (data) {
        let link = $(event.relatedTarget)
        if (link.parent().prop("tagName") == "STRONG") {
          link.parent().replaceWith(link)
        }

        $("#application-modal .modal-content").empty()
        $("#application-modal .modal-content").append(data)
      }
    })
  })

  $("#caseable .table-js-sortable").DataTable({
    order: [5, "desc"],
    searching: false,
    paging: false,
    info: false
  })
})