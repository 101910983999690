// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//= require jquery
//= require jquery_ujs
//= require turbolinks
//= require flatpickr
//= require datatables.net
//= require datatables.net-bs4
//= require_tree .
//= require bootstrap

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import $ from "jquery"
import "datatables.net"
import "datatables.net-bs4"
import "bootstrap/dist/js/bootstrap"
import "bootstrap-icons/font/bootstrap-icons.css"
import flatpickr from "flatpickr"
import "flatpickr/dist/flatpickr.min.css"
import "datatables.net-bs4"
import { Danish } from "flatpickr/dist/l10n/da.js"




window.$ = $
window.jQuery = $ 

require("packs/health_provider_search")
require("packs/cases")
require("packs/case_note")
require("packs/case_search")
require("packs/project_member_search")
require("packs/health_provider_index_search")
require("packs/jquery-sortable") 

Rails.start()
ActiveStorage.start()

flatpickr.localize(Danish)
$.extend( $.fn.dataTable, {
  searching: false,
  paging: false,
  info: false
})

document.addEventListener('DOMContentLoaded', function() {
  flatpickr('.datetimepicker', {
    enableTime: true,
    altInput: true,
    altFormat: "d-m-Y H:i",
    locale: {
      firstDayOfWeek: 1
    }
    
  })
})
document.addEventListener('DOMContentLoaded', function() {
  flatpickr('.datepicker', {
    enableTime: true,
    altInput: true,
    altFormat: "d-m-Y",
    locale: {
      firstDayOfWeek: 1
    }
  })
})


$(function() {

  
  let icon = '<i class="bi bi-caret-down"></i>'
  $(".card-toggle .card-body").hide()
  $(".card-toggle .card-header").prepend(icon)

  $(".card-toggle .card-header").on("click", function() {
    let upArrow = $(this).find(".bi-caret-up")
    let downArrow = $(this).find(".bi-caret-down")
    upArrow.removeClass("bi-caret-up").addClass("bi-caret-down")
    downArrow.removeClass("bi-caret-down").addClass("bi-caret-up")
    $(this).parent().find(".card-body").toggle()
  })
  

  $.fn.dataTable.ext.order.intl = function ( locales, options ) {
      if ( window.Intl ) {
          var collator = new window.Intl.Collator( locales, options );
          var types = $.fn.dataTable.ext.type;
          delete types.order['string-pre'];
          types.order['string-asc'] = collator.compare;
          types.order['string-desc'] = function ( a, b ) {
              return collator.compare( a, b ) * -1;
          };
      }
  };
  
 $.fn.dataTable.ext.order.intl( 'da' );

  $(".table-js-sortable").DataTable()

})
